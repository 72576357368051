import { createRouter, createWebHistory } from 'vue-router'

// import Ausbau from '../views/Ausbau.vue'
// import axios from 'axios'

// const baseURL = import.meta.env.VITE_URL

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  routes: [
    {
      name: 'HomeView',
      path: '/',
      component: () => import('../views/HomeView.vue')
    },
    {
      name: 'AngebotGewerbe',
      path: '/angebot-gewerbe',
      component: () => import('../views/AngebotGewerbe.vue')
    },
    {
      name: 'Lage',
      path: '/lage',
      component: () => import('../views/Lage.vue')
    },
    {
      name: 'Ausbau',
      path: '/ausbau',
      component: () => import('../views/Ausbau.vue')
    },
    {
      name: 'Kontakt',
      path: '/kontakt',
      component: () => import('../views/Kontakt.vue')
    },
    {
      name: 'Impressum',
      path: '/impressum',
      component: () => import('../views/Impressum.vue')
    },
    {
      name: 'DATENSCHUTZERKLÄRUNG',
      path: '/datenschutzerklarung',
      component: () => import('../views/DATENSCHUTZERKLÄRUNG.vue')
    }
  ],
  scrollBehavior() {
    return { top: 0 }
  },
})
export default router

