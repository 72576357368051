<script setup>
import { RouterLink } from 'vue-router'
import Logo from './assets/Logo.vue'
import LogoSmall from './assets/LogoSmall.vue'
// import axios from 'axios'
import { reactive, onMounted } from 'vue'

// const baseURL = import.meta.env.VITE_URL

// const state = ref([])
//
// axios.get(`${baseURL}/api/menus`).then((res) => {
//   console.log(res.data.data)
//   state.value = res.data.data
// })

const navbarState = reactive({
  isScrolled: false
})

const handleScroll = () => {
  navbarState.isScrolled = window.scrollY > 0
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})
</script>

<template>
  <nav
    :class="{
      navbar: true,
      'sticky-top': true,
      'navbar-expand-lg': true,
      'bg-body-tertiary': true,
      'py-0': true,
      scrolled: navbarState.isScrolled
    }"
  >
    <div class="container-fluid bg-white ps-lg-5 pe-lg-0">
      <router-link class="navbar-brand" v-if="!navbarState.isScrolled" to="/"><logo /></router-link>
      <router-link class="navbar-brand" v-if="navbarState.isScrolled" to="/"
        ><logo-small
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0 flex-grow-1 justify-content-center">
<!--          <li class="nav-item">-->
<!--            <router-link class="nav-link" :to="route.attributes.path">-->
<!--              {{ route.attributes.name }}-->
<!--            </router-link>-->
<!--          </li>-->
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/angebot-gewerbe">Angebot Gewerbe </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/ausbau">Ausbau </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/lage">Lage </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/kontakt">Kontakt </router-link>
          </li>
        </ul>
        <router-link class="btn btn-info shaped-border" to="/kontakt">
          BESICHTIGUNG VEREINBAREN
        </router-link>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.navbar {
  align-items: unset;
}
.navbar-brand {
  margin-top: 10px;
  margin-bottom: 10px;
}
.shaped-border {
  align-self: start;
  border-radius: 0 0 0 80px;
  gap: 10px;
  font-size: 14px;
  padding-block: 9px;
  padding-inline: 40px;
}
.nav-link,
.nav-link:active {
  color: var(--bs-secondary);
  font-weight: 300;
  margin-block: 20px;
}
.nav-link.active {
  color: var(--bs-secondary) !important;
  font-weight: bold;
  position: relative;
}
.nav-link.active:before {
  content: '';
  background-image: url(./../assets/img/navbar-item-decor.png);
  position: absolute;
  width: 40px;
  height: 100%;
  background-repeat: no-repeat;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.scrolled .nav-link.active:before {
  display: none;
}
.scrolled .nav-link {
  margin-block: 0;
}
.scrolled .navbar-brand {
  margin-top: 0;
  margin-bottom: 0;
  min-width: 222px;
}
.navbar:not(.scrolled) {
  height: 80px;
  transition: height 0.2s ease-in;
}
.navbar.scrolled {
  height: 45px;
  background: #fff;
  transition: height 0.2s ease-in;
}
.scrolled .navbar-collapse {
  height: 100%;
}
.scrolled .shaped-border {
  height: 45px;
}
.navbar.scrolled:before {
  content: '';
  width: 5px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: transparent;
}
</style>
