import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(router)
app.use(VueAxios, axios)

app.mount('#app')

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/js/bootstrap.js'
// import './assets/main.css'

async function loadModuleStyles() {
    await import('bootstrap/dist/css/bootstrap.css');
    import('./assets/main.css');
}

async function loadModule() {
    // const module =
    await import('bootstrap/dist/js/bootstrap.js');
    // Use module here
}

loadModuleStyles()
loadModule()